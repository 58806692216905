.box {
  height: 136px;
  user-select: none;
  overflow: hidden;
  .headers {
    width: 100%;
    height: 40px;
    background-color: #3d3d3d;
    margin-bottom: 20px;
    .logo {
      float: left;
      margin-left: 183px;
      font-size: 14px;
      color: #b0b0b0;
      cursor: pointer;
      transition: all 0.2s;
      background-image: url("../../../assets/images/bz/logo.png");
      width: 30px;
      height: 30px;
      margin-top: 6px;
      background-size: 100% 100%;
    }
    .timer {
      float: left;
      margin-left: 5px;
      font-size: 14px;
      color: #b0b0b0;
      margin-top: 10px;
      letter-spacing: 0.05em;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        color: #fff;
      }
    }
    ul {
      display: flex;
      justify-content: flex-end;
      margin-right: 150px;
      align-items: center;
      li {
        list-style: none;
        line-height: 40px;
        a {
          margin: 0 12px;
          color: #b0b0b0;
          transition: all 0.2s;
          &:hover {
            color: #fff;
          }
        }
        span {
          color: #b0b0b0;
        }
      }
      .cars {
        width: 130px;
        display: flex;
        justify-content: center;
        transition: all 0.3s;
        cursor: pointer;
        i {
          color: #fff;
          position: relative;
          right: -10px;
        }
        span {
          position: relative;
          right: 10px;
        }
        &:hover {
          a,
          i,
          span {
            color: var(--themeColor);
          }
          background: #fff;
        }
      }
    }
  }
  .tabsAndInputBox {
    position: relative;
    max-width: 1226px;
    margin: 0 auto;
    top: -16px;
    .tabs {
      width: 1226px;
      margin: 0 auto;
      padding-left: 246px;
    }
    .left {
      font-size: 40px;
      font-weight: 540;
      position: absolute;
      padding-right: 40px !important;
      background: url("../../../assets/images/bz/bz.png");
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      transition: all 0.3s;
      top: 20px;
      font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
        "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
      animation: loginAnimate 5s infinite alternate linear;
      @keyframes loginAnimate {
        0% {
          background-position: 0;
        }
        100% {
          background-position: 100%;
        }
      }
    }
    .searchInput {
      position: absolute;
      right: 0;
      bottom: 10px;
    }
  }
}
.userImg {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  top: 3px;
  right: -10px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: relative;
    top: -4px;
    object-fit: cover;
  }
}



