.box {
  width: 1226px;
  margin: 20px auto 0;
  .topTitle {
    width: 100%;
    height: 40px;
    font-size: 22px;
    line-height: 58px;
    font-weight: 400;
    display: flex;
    justify-content: space-between;
   span{
    user-select: none;
    &:last-child{
      font-size: 16px;
      font-weight: 200;
      margin-right: 16px;
      cursor: pointer;
      transition: all .3s linear;
      i{
        margin-left: 3px;
        transition: all .3s linear;
      }
      &:hover{
        color: var(--themeColor);
        i{
          color: var(--themeColor);
        }
      }
    }
   }
  }
  .itemBox {
    width: 100%;
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0;
      &::after {
        content: '';
        width: 48.5%;
        border: 1px solid transparent;
      }
      li {
        list-style: none;
        cursor: pointer;
        width: 23%;
        height: 368px;
        margin-bottom: 35px;
        background-color: #fff;
        text-align: center;
        transition: all .2s linear;
        border-radius:5px;
        padding: 0 18px;
        box-sizing: border-box;
        &:hover{
          box-shadow: 0 15px 30px rgb(0 0 0 / 10%);
          -webkit-transform: translate3d(0,-2px,0);
          transform: translate3d(0,-8px,0);
        }
        .imgs {
          width: 190px;
          height: 190px;
          margin: 30px auto 0;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .title {
          font-size: 15px;
          font-weight: 440;
          color: #333;
          width: 90%;
          margin: 24px auto 8px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          span{
            overflow: hidden;
            font-size: 12px;
            // background:var(--themeColor) ;
            background: linear-gradient(to right bottom,
            rgb(158, 41, 41),
            rgb(214, 60, 60),
            rgb(120, 24, 24),
            ) !important;
            color: #fff;
            padding: 0 3px;
            margin-right: 3px;
            border-radius: 3px;
          }
        }
        .desc {
          height: 18px;
          font-size: 12px;
          width: 90%;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin: 8px auto 10px;
          color: #707070;
        }
        .price {
          color: var(--themeColor);
          .prePrice{
            color: var(--themeColor);
          }
          .nowPrice{
            margin-left: 6px;
            text-decoration: line-through;
            color: #757575;
          }
         
        }
      }
    }
  }
}
