.swiper {
  position: relative;
  width: 100%;
  height: 100%;
  .swiperBoss {
    margin: 0 auto;
    height: 100%;
    overflow: hidden !important;
    .swiperBox {
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .list {
    min-width: 1100px;
    position: absolute;
    top: 46%;
    transform: translate(-51%, -50%);
    left: 51%;
    height: 400px;
    border-radius: 8px;
    display: flex;

    .left {
      background-color: rgba(255, 255, 255, 0.9);
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 36px;
      width: 200px;
      height: 100%;
      border-radius: 8px ;
      padding: 15px 0;
      .item {
        height: 45px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        padding: 0 20px;
        transition: all 0.3s;
        align-items: center;
        &:hover {
          background: #fff;
          border-radius: 4px;
          color: var(--themeColor);

          span {
            &:last-child {
              color: var(--themeColor);
            }
          }
        }

        span {
          &:first-child {
            width: 133px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 15px;
          }
          &:last-child {
            color: rgb(175, 175, 175);
            font-size: 17px;
          }
        }
      }
    }
    .right {
      width: 900px;
      height: 100%;
      left: 300px;
      top: 0;
      padding: 20px 30px;
      background-color: #fff;
      box-shadow: rgb(0 0 0 / 10%) 0px 0px 36px;
      display: flex;
      flex-wrap: wrap;
      border-radius: 0 8px 8px 0;
      justify-content: flex-start;
      align-content: flex-start;

      .contentItem {
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s;
        width: 239px;
        height: 70px;
        margin: 10px 15px;
        position: relative;
        box-sizing: border-box;

        div {
          position: absolute;
          min-width: 10px;
          height: 16px;
          top: 6px;
          left: 61px;
          padding: 0 3px;
          box-sizing: border-box;
          font-size: 12px;
          background: var(--themeColor);
          background: linear-gradient(
            to right bottom,
            rgb(158, 41, 41),
            rgb(214, 60, 60),
            rgb(120, 24, 24)
          ) !important;
          color: #fff;
          border-radius: 3px;
          text-align: center;
          line-height: 16px;
        }
        &:hover {
          color: var(--themeColor);
        }
        img {
          width: 55px;
          height: 55px;
        }
        span {
          display: inline-block;
          width: 166px;
          margin-left: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .selectAll {
        width: 239px;
        height: 70px;
        margin: 10px 25px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .textTitle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 145px;
          height: 40px;
          border: 1px solid #ccc;
          border-radius: 20px;
          text-align: center;
          cursor: pointer;
          transition: all 0.3s;
          position: relative;
          &:hover {
            border: 1px solid var(--themeColor);
            color: var(--themeColor);
          }
        }
      }
    }
  }
}
.empty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active {
  color: var(--themeColor);
  .charts {
    color: var(--themeColor);
  }
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.learn-more {
  width: 12rem;
  height: auto;
}

button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: linear-gradient(
    to right bottom,
    rgb(158, 41, 41),
    rgb(214, 60, 60),
    rgb(120, 24, 24)
  ) !important;
  border-radius: 1.625rem;
}

button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .circle {
  width: 100%;
}

button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

button:hover .button-text {
  color: #fff;
}
