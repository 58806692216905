.home{
  width: 100%;
  margin:0 auto;
  overflow: hidden;
  .swiperContain{
    width: 100%;
    height: 500px;
    overflow: hidden;
  }
}
.sscs{
  min-height: 1000px;
  overflow: hidden;
  background-color: var(--bgColor);
}