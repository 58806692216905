.payImg{
  width: 160px;
  height: 160px;
  margin: 0 auto;
  margin-top: 10px;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.btns{
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 20px;
  .success{
    position: relative;
    left: 16px;
  }
  .cancel{
    position: relative;
    left: -16px;
  }
}